<template>
    <q-page>
      <div class="q-mb-xl q-gutter-md" align="center">
  
        <q-card class="my-card" style="min-height: 500px" flat>
          <q-card-section align="left">
            <q-img src="@/assets/warehouse.jpg" />
            <div class="text-header-color text-bold text-center" v-html="$t('error.404.header')"></div>
            <q-separator inset class="q-ma-md" />
            <div class="text-h5 text-light" v-html="$t('error.404.text')"></div>
            <q-separator inset class="q-ma-md" />
          </q-card-section>
        </q-card>
      </div>
      <q-page-scroller position="bottom-right" :scroll-offset="300" :offset="[18, 18]">
        <q-btn fab icon="keyboard_arrow_up" color="primary" />
      </q-page-scroller>
    </q-page>
  
  
  </template>
  
  <style lang="sass" scoped>
  .my-card
    width: 95%
  </style>
  
  
  
  <script>
  
  export default {
    name: 'PathNotFound',
  }
  </script>
  
<template>
  <q-page style="margin-top: -80px">
    <q-img src="../assets/raptor_in_smoke.png" :position="absolute" style="height: 100vh; " />
    <div class="q-pa-md absolute-top" style="height: 100vh;">

      <div class="row no-wrap">
        <div class="col-auto">
          <q-img style="width: 8rem;" src="../assets/logo.png" />
        </div>
        <div class="row">
          <p class="header"><mark class="blue">DIS</mark>TECH<mark class="sub-header">&#8203;.Systems</mark></p>
        </div>
      </div>
      <div class="row absolute-center" style="width: 95%">
        <p class="banner-text">Industrial 3D-Printing solutions.</p>
      </div>

      <div v-bind:style="{ opacity: opacity_banner_buttons }" class="row absolute-bottom wrap" align="center">
        <div class="col">
          <q-avatar class="gt-md avatar cursor-pointer" size="10rem" font-size="100px" icon="precision_manufacturing"
            @click="$emit('setCurrentTabProducts')" />
          <q-avatar class="md avatar cursor-pointer" size="8rem" font-size="90px" icon="precision_manufacturing"
            @click="$emit('setCurrentTabProducts')" />
          <q-avatar class="lt-md avatar cursor-pointer" size="3.9rem" font-size="45px" icon="precision_manufacturing"
            @click="$emit('setCurrentTabProducts')" />
          <p class="services-text cursor-pointer" @click="$emit('setCurrentTabProducts')">{{ $t("opener.systems.name")
            }}</p>
        </div>
        <div class="col">
          <q-avatar class="gt-md avatar cursor-pointer" size="10rem" font-size="100px" icon="speed"
            @click="$emit('setCurrentTabProductsXlms')" />
          <q-avatar class="md avatar cursor-pointer" size="8rem" font-size="90px" icon="speed"
            @click="$emit('setCurrentTabProductsXlms')" />
          <q-avatar class="lt-md avatar cursor-pointer" size="3.9rem" font-size="45px" icon="speed"
            @click="$emit('setCurrentTabProductsXlms')" />
          <p class="services-text cursor-pointer" @click="$emit('setCurrentTabProductsXlms')">{{
            $t("opener.upgrades.name") }}</p>
        </div>
        <div class="col">
          <q-avatar class="gt-md avatar cursor-pointer" size="10rem" font-size="100px" icon="build"
            @click="$emit('setCurrentTabServices')" />
          <q-avatar class="md avatar cursor-pointer" size="8rem" font-size="90px" icon="build"
            @click="$emit('setCurrentTabServices')" />
          <q-avatar class="lt-md avatar cursor-pointer" size="3.9rem" font-size="45px" icon="build"
            @click="$emit('setCurrentTabServices')" />
          <p class="services-text cursor-pointer" @click="$emit('setCurrentTabServices')">{{
            $t("opener.maintenanceAndRepair.name") }}</p>
        </div>
        <div class="col">
          <q-avatar class="gt-md avatar cursor-pointer" size="10rem" font-size="100px" icon="forum"
            @click="$emit('setCurrentTabServices')" />
          <q-avatar class="md avatar cursor-pointer" size="8rem" font-size="90px" icon="forum"
            @click="$emit('setCurrentTabServices')" />
          <q-avatar class="lt-md avatar cursor-pointer" size="3.9rem" font-size="45px" icon="forum"
            @click="$emit('setCurrentTabServices')" />
          <p class="services-text cursor-pointer" @click="$emit('setCurrentTabServices')">{{
            $t("opener.trainingAndSupport.name") }}</p>
        </div>
        <div class="col">
          <q-avatar class="gt-md avatar cursor-pointer" size="10rem" font-size="100px" icon="engineering"
            @click="$emit('setCurrentTabProductsXlms')" />
          <q-avatar class="md avatar cursor-pointer" size="8rem" font-size="90px" icon="engineering"
            @click="$emit('setCurrentTabProductsXlms')" />
          <q-avatar class="lt-md avatar cursor-pointer" size="3.9rem" font-size="45px" icon="engineering"
            @click="$emit('setCurrentTabProductsXlms')" />
          <p class="services-text cursor-pointer" @click="$emit('setCurrentTabProductsXlms')">{{
            $t("opener.development.name") }}</p>
        </div>
      </div>
    </div>
  </q-page>
  <br />
  <q-separator inset />
  <q-page>
    <div class="q-pa-md q-gutter-md" align="center" v-scroll="onScroll">

      <q-card class="my-card cursor-pointer" bordered @click="$emit('setCurrentTabProducts')">
        <q-card-section :horizontal="$q.screen.gt.sm">
          <q-card-section style="min-width: 250px;">
            <q-avatar class="avatar" size="7rem" font-size="100px" icon="precision_manufacturing" />
            <p class="services-text-description">{{ $t("opener.systems.name") }}</p>
          </q-card-section>
          <q-card-section style="width: 100%; height: 100%; margin: auto">
            <q-separator v-if="$q.screen.lt.md" inset />
            <div class="text-h6 text-regular" v-html="$t('opener.systems.headline')" />
            <q-separator v-if="$q.screen.lt.md" inset />
          </q-card-section>
        </q-card-section>
      </q-card>

      <q-card class="my-card cursor-pointer" bordered @click="$emit('setCurrentTabProductsXlms')">
        <q-card-section :horizontal="$q.screen.gt.sm">
          <q-card-section style="min-width: 250px;">
            <q-avatar class="avatar" size="7rem" font-size="100px" icon="speed" />
            <p class="services-text-description">{{ $t("opener.upgrades.name") }}</p>
          </q-card-section>
          <q-card-section style="width: 100%; height: 100%; margin: auto">
            <q-separator v-if="$q.screen.lt.md" inset />
            <div class="text-h6 text-regular" v-html="$t('opener.upgrades.headline')" />
            <q-separator v-if="$q.screen.lt.md" inset />
          </q-card-section>
        </q-card-section>
      </q-card>


      <q-card class="my-card cursor-pointer" bordered @click="$emit('setCurrentTabServices')">
        <q-card-section :horizontal="$q.screen.gt.sm">
          <q-card-section>
            <q-avatar class="avatar" size="7rem" font-size="100px" icon="build" />
            <p class="services-text-description">{{ $t("opener.maintenanceAndRepair.name") }}</p>
          </q-card-section>
          <q-card-section style="width: 100%; height: 100%; margin: auto">
            <q-separator v-if="$q.screen.lt.md" inset />
            <div class="text-h6 text-regular" v-html="$t('opener.maintenanceAndRepair.headline')" />
            <q-separator v-if="$q.screen.lt.md" inset />
          </q-card-section>
        </q-card-section>
      </q-card>

      <q-card class="my-card cursor-pointer" bordered @click="$emit('setCurrentTabServices')">
        <q-card-section :horizontal="$q.screen.gt.sm">
          <q-card-section style="min-width: 250px;">
            <q-avatar class="avatar" size="7rem" font-size="100px" icon="forum" />
            <p class="services-text-description">{{ $t("opener.trainingAndSupport.name") }}</p>
          </q-card-section>
          <q-card-section style="width: 100%; height: 100%; margin: auto">
            <q-separator v-if="$q.screen.lt.md" inset />
            <div class="text-h6 text-regular" v-html="$t('opener.trainingAndSupport.headline')" />
            <q-separator v-if="$q.screen.lt.md" inset />
          </q-card-section>
        </q-card-section>
      </q-card>


      <q-card class="my-card cursor-pointer" bordered @click="$emit('setCurrentTabProductsXlms')">
        <q-card-section :horizontal="$q.screen.gt.sm">
          <q-card-section>
            <q-avatar class="avatar" size="7rem" font-size="100px" icon="engineering" />
            <p class="services-text-description">{{ $t("opener.development.name") }}</p>
          </q-card-section>
          <q-card-section style="width: 100%; height: 100%; margin: auto">
            <q-separator v-if="$q.screen.lt.md" inset />
            <div class="text-h6 text-regular" v-html="$t('opener.development.headline')" />
            <q-separator v-if="$q.screen.lt.md" inset />
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
    <q-page-scroller position="bottom-right" :scroll-offset="300" :offset="[18, 18]">
      <q-btn fab icon="keyboard_arrow_up" color="primary" />
    </q-page-scroller>
  </q-page>
</template>
<script>
import { ref } from 'vue'
import 'animate.css'
export default {
  name: 'OpenerComponent',
  setup() {
    var opacity_banner_buttons = ref(1.0);
    function onScroll(position) {
      opacity_banner_buttons.value = (1.0 - position / 500) > 0 ? 1.0 - position / 500 : 0
    }
    return {
      onScroll,
      opacity_banner_buttons,
    }
  }
}
</script>

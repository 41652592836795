import { createRouter, createWebHistory } from "vue-router";
import Opener from '@/components/opener.vue'
import PathNotFound from '@/components/pathNotFound.vue'
/*import Products from '@/components/products.vue'
import Stories from '@/components/stories.vue'
import Services from '@/components/services.vue'
import Company from '@/components/company.vue'
import Contact from '@/components/contact.vue'*/

const routes = [
  {
    path: "/",
    name: "opener",
    component: Opener
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/components/products.vue")
  },
  {
    path: "/services",
    name: "services",
    component: () => import("@/components/services.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/components/contact.vue"),
    props: true
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/components/about.vue")
  },
  {
    path: '/:pathMatch(.*)*',
    name: "pathNotFound",
    component: PathNotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 110,
      }
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  }
})

export default router;